import { CheckMark, Switch } from '@valuecase/ui-components'
import { SettingsInfo } from './SettingsInfo'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useMemo } from 'react'
import { useNotifications } from '@/utils/Notifications/Notifications'
import { useLDflags } from '@/launchdarkly/Launchdarkly'

const AiWritingAssistantSettings = () => {
  const { success } = useNotifications()
  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Settings updated successfully')
    },
  })
  const { flags } = useLDflags()

  const aiWritingAssistIsEnabled = useMemo(() => {
    return companySettings?.aiWritingAssistantIsEnabled
  }, [companySettings])

  const aiWritingAssistantIsOptOut = useMemo(
    () => companySettings?.aiWritingAssistantOptOut ?? false,
    [companySettings],
  )

  const handleToggle = () => {
    updateCompanySettings({
      aiWritingAssistantIsEnabled: !aiWritingAssistIsEnabled,
    })
  }

  const handleOptOutToggle = () => {
    updateCompanySettings({
      aiWritingAssistantOptOut: !aiWritingAssistantIsOptOut,
    })

    const shouldSwitchAiWritingAssistantOff = !aiWritingAssistantIsOptOut === true

    if (shouldSwitchAiWritingAssistantOff) {
      updateCompanySettings({
        aiWritingAssistantIsEnabled: false,
      })
    }
  }

  if (flags && flags['ai-writing-assistant-opt-out-toggle']) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex justify-between w-full items-start'>
          <SettingsInfo
            title='AI Tools & Open AI Data processing'
            description='Enable the AI writing assistant and the use of more AI tools in the future. Data may be sent to OpenAI servers outside the EU for processing, but not used for training the AI, in accordance with our Data Processing Agreement. The feature can at any time be turned off by an authorized admin.'
          />
          <div className='relative flex flex-col h-full justify-between'>
            <Switch
              disabled={aiWritingAssistantIsOptOut}
              checked={aiWritingAssistIsEnabled}
              onChange={handleToggle}
            />
          </div>
        </div>
        <div className='flex justify-between'>
          <div className='flex flex-col gap-2'>
            <span className='font-bold text-xs text-grey-s6'>
              Opt-Out of Open AI Data processing
            </span>
            <span className='text-xxs text-grey-s5 max-w-[350px]'>
              We&apos;ll automatically enable the Open AI Data processing after a 4 week notice
              period on 11. November 2024, if you have not objected until then. Tick the box to
              opt-out of Open AI Data processing.
            </span>
          </div>
          <div className='p-2'>
            <CheckMark checked={aiWritingAssistantIsOptOut} onClick={handleOptOutToggle} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='AI Writing Assistant'
        description='Enable the AI writing assistant. Data may be sent to OpenAI servers outside the EU for processing, but not used for training the AI.'
      />
      <div className='relative flex'>
        <Switch checked={aiWritingAssistIsEnabled} onChange={handleToggle} />
      </div>
    </div>
  )
}

export default AiWritingAssistantSettings

import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'
import { cn } from '../../lib'
import { IonIcon } from '../../Icons/IonIcon'

export const ButtonGroup = ({ children }: { children: ReactNode }) => {
  return <div className={'inline-flex h-10 items-center justify-center rounded-lg'}>{children}</div>
}
export const ButtonGroupItem = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { leadingIcon?: string }
>(({ className, children, leadingIcon, ...props }, ref) => {
  return (
    <button
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap px-3 h-9 text-xs shadow-none font-semibold transition-all outline-none border-r-[1px] border-t-[1px] border-b-[1px] border-grey-s2 bg-white',
        'hover:bg-grey-s1 hover:text-grey-s6',
        'first:rounded-l-lg first:border-l-[1px]',
        'last:rounded-r-lg last:border-r-[1px]',
        'disabled:pointer-events-none disabled:opacity-50 hover:text-grey-s6',
        'text-grey-s5',
        className,
      )}
      {...props}
    >
      {leadingIcon && <IonIcon name={leadingIcon} className={'w-4 h-4 text-grey-s5'} />}
      {children}
    </button>
  )
})
ButtonGroupItem.displayName = 'ButtonGroupItem'

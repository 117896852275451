import { ReadSpacesListDTO } from '@/modules/spaces/hooks/types'
import useAdditionalFilters from '@/modules/spaces/hooks/useAdditionalFilters'
import { SpaceFilterMapStatus } from '@/modules/spaces/hooks/useReadSpaces'
import { useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import { useReadTemplates } from '@/modules/templates/hooks/useReadTemplates'
import { Table } from '@tanstack/react-table'
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Input2,
  Highlight,
} from '@valuecase/ui-components'
import { useCallback, useMemo, useState } from 'react'
import { mapActionPlanStatusToLabel, mapActionPlanStatusToStyleVariant } from '../spacesList'
import SpaceNameFilter from './SpaceNameFilter'
import SpaceOwnedByFilter from './SpaceOwnedByFilter'
import SpaceTypeFilter from './SpaceTypeFilter'
import SpaceByTeamsFilter from './SpaceByTeamFilter'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import { useLDflags } from '@/launchdarkly/Launchdarkly'

const FilterContainer = ({
  table,
}: {
  table: Table<
    ReadSpacesListDTO & {
      ownerName: string
      ownerEmail?: string | undefined
      picture?: string | undefined
    }
  >
}) => {
  const {
    mapStatus,
    templateIds: templatesInFilter,
    setTemplates,
    setMapStatus,
    resetFilters,
    filters,
  } = useSpaceFilter()
  const { templates } = useReadTemplates()
  const { data: teams } = useReadTeams()
  const { flags } = useLDflags()
  const [templateSearchTerm, setTemplateSearchTerm] = useState('')
  const {
    allFiltersVisible,
    handleFilterDropdownOpenChange,
    filterDropdownOpen,
    filterActive,
    anyFilterActive,
    visibleAdditionalFilters,
  } = useAdditionalFilters(['mapStatus', 'templateIds'], filters)

  const filterableMAPStates = useMemo(
    (): SpaceFilterMapStatus[] => [
      'notCreated',
      'notStarted',
      'inProgress',
      'overdue',
      'stalled',
      'completed',
    ],
    [],
  )

  const templateStateSelected = useCallback(
    (templateId: string) => {
      return templatesInFilter?.includes(templateId)
    },
    [templatesInFilter],
  )

  const mapStateSelected = useCallback(
    (progressState: SpaceFilterMapStatus) => {
      return mapStatus?.includes(progressState)
    },
    [mapStatus],
  )

  const toggleMAPStateSelection = useCallback(
    (progressState: SpaceFilterMapStatus) => {
      const selectedMAPStates = (mapStatus as SpaceFilterMapStatus[]) ?? []

      if (mapStateSelected(progressState)) {
        setMapStatus(
          selectedMAPStates.filter((state: SpaceFilterMapStatus) => state !== progressState),
        )
      } else {
        setMapStatus([...selectedMAPStates, progressState])
      }
    },
    [mapStatus, mapStateSelected, setMapStatus],
  )

  const toggleTemplateStateSelection = useCallback(
    (templateId: string) => {
      const selectedTemplateIds = templatesInFilter ?? []

      if (templateStateSelected(templateId)) {
        setTemplates(selectedTemplateIds.filter((id) => id !== templateId))
      } else {
        setTemplates([...selectedTemplateIds, templateId])
      }
    },
    [templateStateSelected, templatesInFilter, setTemplates],
  )

  const templateStateSelectionLabel = useMemo(() => {
    if (!templatesInFilter || templatesInFilter.length === 0) {
      return 'Any template'
    }

    const selectedTemplateTitles = templatesInFilter
      .map((id) => templates?.find((template) => template.id === id)?.title)
      .filter(Boolean)

    if (selectedTemplateTitles.length === 1) {
      return selectedTemplateTitles[0]
    }

    return `Templates (${selectedTemplateTitles.length})`
  }, [templatesInFilter, templates])

  const mapStateSelectionLabel = useMemo(() => {
    if (mapStatus?.length === 0 || !mapStatus) {
      return 'Any action plan'
    }

    if (mapStatus?.length === 1) {
      return mapActionPlanStatusToLabel(mapStatus[0])
    }

    return `Statuses (${mapStatus?.length ?? 0})`
  }, [mapStatus])

  const columnDescription = useCallback((columnId: string) => {
    if (columnId === 'mapStatus' || columnId === 'mapProgress') {
      return 'of action plan'
    }
  }, [])

  const filteredTemplates = useMemo(() => {
    if (!templateSearchTerm) {
      return templates
    }

    return templates?.filter((template) =>
      template.title.toLowerCase().includes(templateSearchTerm.toLowerCase()),
    )
  }, [templates, templateSearchTerm])

  const handleTemplatesFilterOnOpenChange = useCallback(
    async (open: boolean) => {
      handleFilterDropdownOpenChange('templateIds', open)
      if (open) {
        setTemplateSearchTerm('')
      }
    },
    [handleFilterDropdownOpenChange],
  )

  const additionalFilterDropdowns = useMemo(() => {
    const filterDropdowns = [
      {
        columnId: 'templateIds',
        component: (
          <DropdownMenu
            open={filterDropdownOpen('templateIds')}
            onOpenChange={handleTemplatesFilterOnOpenChange}
          >
            <DropdownMenuTrigger asChild>
              <DropdownMenuButton className={'max-w-[220px]'} icon='grid-outline'>
                {templateStateSelectionLabel}
              </DropdownMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-60' align='start'>
              <div className='p-1'>
                <Input2
                  type='text'
                  placeholder='Search owners'
                  value={templateSearchTerm}
                  onChange={(e) => setTemplateSearchTerm(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent dropdown from capturing key events
                  trailingIcon='search'
                />
              </div>
              <DropdownMenuSeparator />

              <DropdownMenuContentScrollable className={'max-w-[220px]'}>
                {filteredTemplates?.map((template) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={template.id}
                      checked={templateStateSelected(template.id)}
                      onCheckedChange={() => toggleTemplateStateSelection(template.id)}
                      onSelect={(event) => event.preventDefault()}
                    >
                      <Highlight searchTerm={templateSearchTerm}>{template.title}</Highlight>
                    </DropdownMenuCheckboxItem>
                  )
                })}
              </DropdownMenuContentScrollable>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
      {
        columnId: 'mapStatus',
        component: (
          <DropdownMenu
            open={filterDropdownOpen('mapStatus')}
            onOpenChange={(open) => handleFilterDropdownOpenChange('mapStatus', open)}
          >
            <DropdownMenuTrigger asChild>
              <DropdownMenuButton icon='checkmark-done-outline'>
                {mapStateSelectionLabel}
              </DropdownMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='start'>
              {filterableMAPStates.map((state) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={state}
                    checked={mapStateSelected(state)}
                    onCheckedChange={() => toggleMAPStateSelection(state)}
                    onSelect={(event) => {
                      event.preventDefault()
                    }}
                  >
                    <Badge variant={mapActionPlanStatusToStyleVariant(state)}>
                      {mapActionPlanStatusToLabel(state)}
                    </Badge>
                  </DropdownMenuCheckboxItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ]

    return visibleAdditionalFilters.map((id) => {
      const filter = filterDropdowns.find((f) => f.columnId === id)
      if (!filter) {
        return null
      }
      return filter.component
    })
  }, [
    filterDropdownOpen,
    handleTemplatesFilterOnOpenChange,
    templateStateSelectionLabel,
    templateSearchTerm,
    filteredTemplates,
    mapStateSelectionLabel,
    filterableMAPStates,
    visibleAdditionalFilters,
    templateStateSelected,
    toggleTemplateStateSelection,
    handleFilterDropdownOpenChange,
    mapStateSelected,
    toggleMAPStateSelection,
  ])

  return (
    <div className={'flex mb-4 gap-2 justify-start'}>
      <div className={'flex gap-2 grow items-start flex-wrap mr-12'}>
        <SpaceNameFilter />
        <SpaceOwnedByFilter />
        {flags && flags['teams-management'] && !!teams?.length && <SpaceByTeamsFilter />}
        <SpaceTypeFilter />

        {additionalFilterDropdowns}

        <DropdownMenu>
          {!allFiltersVisible && (
            <DropdownMenuTrigger asChild>
              <div>
                <Button icon='add-outline' style='flat-sidebar' label={'Add filter'} />
              </div>
            </DropdownMenuTrigger>
          )}
          <DropdownMenuContent align='end'>
            {!filterActive('mapStatus') && (
              <DropdownMenuItem
                onClick={() => {
                  // timeout fixes a small animation flickering
                  setTimeout(() => {
                    handleFilterDropdownOpenChange('mapStatus', true)
                  }, 50)
                }}
                icon={'checkmark-done-outline'}
              >
                Action plan status
              </DropdownMenuItem>
            )}

            {!filterActive('templateIds') && (
              <DropdownMenuItem
                onClick={() => {
                  // timeout fixes a small animation flickering
                  setTimeout(() => {
                    handleFilterDropdownOpenChange('templateIds', true)
                  }, 50)
                }}
                icon={'grid-outline'}
              >
                Template
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className={'flex gap-2 items-start'}>
        {anyFilterActive && (
          <Button
            style='flat-sidebar'
            label={'Reset filters'}
            onClick={resetFilters}
            cssStyle={{ color: 'var(--theme-grey-s5)' }}
          />
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <DropdownMenuButton
              className='ml-auto'
              icon='c_table-settings'
              data-intercom-target='Column selection'
            >
              Columns
            </DropdownMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    description={columnDescription(column.id)}
                  >
                    {typeof column.columnDef.header === 'string'
                      ? column.columnDef.header
                      : column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default FilterContainer
